.custom-input::placeholder {
    color: #b8bdc9;
    /* Placeholder text color */
    font-size: 12px;
}


.customHead-input::placeholder {
    color: #b8bdc9;
    /* Placeholder text color */
    font-size: 16px;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.custom-scrollbars {
    /* Set the width of the scrollbar */
    /*scrollbar-width: thin;*/
    scrollbar-color: #37404a #545f6f;
    /* Scrollbar track color and scrollbar thumb color */

    /* WebKit-based browsers */

    &::-webkit-scrollbar {
        width: 16px;
        /* Set the width of the scrollbar */
        border-right: 1px solid #4a5361;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #2f333a;
        /* Set the color of the scrollbar thumb */
        border-radius: 10px;
        /* Set the border radius of the scrollbar thumb */
    }

    &::-webkit-scrollbar-track {
        background-color: #545f6f;
        /* Set the color of the scrollbar track */
    }
}


.customHead-input {
    border: none;
    outline: none;
    user-select: text;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.45;
    /*color: #007BFF;*/
}

.custom-input-wrapper {
    border: 1px solid #b8bdc9;
    border-radius: 3px;
    padding: 10px;
    margin-bottom: 8px;
    width: 180px;
    position: relative;
    height: 40px;
}

.custom-input-wrapper @media screen and(min-width: 900px) {
    width: 260px;
}

.custom-input {
    border: none;
    outline: none;
    user-select: text;
    word-wrap: break-word;
    white-space: pre-wrap;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.45;
    color: #007BFF;
    position: absolute;
    right: 50%;
    top: 50%;
    transform: translate(0, -50%);
    background-color: transparent;
    z-index:100000
}


.react-datepicker, .react-datepicker__time-container, .react-datepicker-popper {
    z-index: 3000 !important; /* Ensure it's above the modal */
    position: relative !important; /* Prevent it from being clipped */
}


.iconDatePicker {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}


.optionWithPadding {
    padding: 10px;
}

.calendar-day-column:empty {
    visibility: hidden;
    margin-top: 40px;
}

.friday {
    background-color: #f8f8f8;
    /* Light gray background */
    color: #777;
    /* Dim text color */
    cursor: not-allowed;
    /* Change cursor to indicate non-interactivity */
}

.day-number {
    font-size: 18px;
}

/* Add any additional styles as needed */


.custom-select {
    position: relative;
    display: inline-block;
}

.custom-select select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: transparent;
    padding: 6px 14px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
}


.captcha-container {
    width: 100%;
}


.fade-container {
    opacity: 0;
    max-height: 1000px;
    /* Set a reasonable maximum height */
    /*overflow: hidden;*/
    transition: opacity 0.5s ease-in-out, max-height 0.5s ease-in-out;
    /* Adjust the duration and easing as needed */
}

.fade-in {
    opacity: 1;
    max-height: 1000px;
    /* Set the same maximum height as above */
}

.fade-out {
    opacity: 0;
    max-height: 0;
}


.FillInput {
    border: none;
    outline: none;
    userSelect: text;
    wordWrap: break-word;
    whiteSpace: pre-wrap;
    width: fit-content !important;
    max-width: fit-content !important;
    fontSize: 14px;
    fontWeight: 500;
    lineHeight: 1.45;
}


.g-recaptcha {
    transform: scale(0.7);
    margin-left: 55px;
    /*transform: translate();*/
    transform-origin: 0 0;
}

@media screen and (min-width: 900px) {
    .g-recaptcha {
        transform: scale(1);
    }
}


.table-container {
    /* max-width: 100%; */
    overflow-x: auto;
}

.custom-table {
    border-spacing: 20px;
    border-collapse: collapse;
    /* Collapse borders to draw continuous lines */
    min-width: 490px;
    /* Set your desired minimum width here */
    width: 100%;
    /* margin-right: 20px; */
}

.custom-table th,
.custom-table td {
    border: 1px solid #c3cad8;
    /* Add borders to each cell */
    padding: 8px;
    /* Add padding to cells */
}

.custom-table .row-header {
    border-right: 1px solid #c3cad8;
    width: 140px;
}


.custom-table th {
    background-color: #e5eaf4;
    /* Add background color to header cells */
}


.input-container {
    display: flex;
    align-items: center;
}

.input-container input {
    flex: 1;
}


.input-container input[type="radio"] {
    margin: 0 auto;
    /* Center the radio button horizontally */
    display: flex;
    /* Align the radio button and label vertically */
    align-items: center;
}

.addColumn {
    transform: rotate(90deg);
    color: darkgray;

    &:hover {
        color: cornflowerblue;
    }
}


.AddColumn {
    color: darkgray;

    &:hover {
        color: cornflowerblue;
    }
}


.delete-icon:hover> :last-child {
    visibility: visible;
}

.navbar-item:hover {
    background-color: rgba(255, 255, 255, 0.25);
}



.react-switch {
    vertical-align: middle;
    margin-left: 4px;
}


.iphone {
    background-image: url('../public/assets/images/iphone-xr.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    position: relative;
}


.item-hover {
    transition: background-color 0.3s ease;
}


.item-hover:hover .title {
    color: white;
}

.item-hover:hover .subtitle {
    color: white;
}

.item-hover:hover .iconCondition {
    background-color: white;
}


.item-hover:hover .iconCondition.fill1 svg {
    fill: #09f;
    /* Change to your desired color for item with ID 1 */
}

.item-hover:hover .iconCondition.fill2 svg {
    fill: #09f;
}

.item-hover:hover .iconCondition.fill3 svg {
    fill: #09f;
}


.item-hover:hover .iconCondition.fill4 svg {
    fill: #049e38;
}


.item-hover:hover .iconCondition.fill5 svg {
    fill: #049e38;
}

.item-hover:hover .iconCondition.fill6 svg {
    fill: #9c4dd3;
}


.hide-scrollbars {
    scrollbar-width: none;
    /* Firefox */
    -ms-overflow-style: none;
    /* Internet Explorer 10+ */
}

.hide-scrollbars::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera */
}

.input-with-overflow {
    resize: none;
    /* Prevents users from resizing the textarea */
    overflow-y: auto;
    /* Enables vertical scrolling if the content exceeds the height */
    overflow-wrap: break-word;
    /* Allows text to break into new lines when necessary */
    height: auto;
    /* Allows the height to adjust based on content */
}


.positionCenter{
    position: absolute;
    top: 50%;
    right: 2%;
    transform: translate(0%, -50%);
}


.typoCenter{
    position: absolute;
    top: 50%;
    right: 20%;
    transform: translate(0%, -50%);
}

@media screen and (min-width: 400px) {
    .typoCenter {
        right: 16%;
    }
}

@media screen and (min-width: 480px) {
    .typoCenter {
        right: 10%;
    }
}
