.select-with-search-container {
    position: relative;
    height: 78px;
}

.custom-select-country {
    cursor: pointer;
    padding: 8px;
    border: 2px solid rgb(60, 61, 55);
    border-radius: 4px;
}

.dropdown-content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 100%;
    border: 1px solid #ccc;
    background-color: #fff;
    max-height: 180px;
    overflow-y: auto;
}


.dropdown-content::-webkit-scrollbar {
    display: none; /* Hide scrollbar for WebKit browsers */
}

/* Optional: Hide scrollbar for Firefox */
.dropdown-content {
    scrollbar-width: none;
}


.search-container {
    display: flex;
    align-items: center;
    padding: 4px;
}

.search-icon {
    width: 16px;
    height: 16px;
    margin-right: 8px;
}

.dropdown-content input[type="text"] {
    flex: 1;
    padding: 8px;
    border: none;
    border-radius: 4px;
    outline: none;
}

.option {
    padding: 8px;
    cursor: pointer;
}

.option:hover {
    background-color: #f0f0f0;
}