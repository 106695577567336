.ck.ck-editor__editable_inline:not(.ck-editor__nested-editable) {
    min-height: 400px;
}
/* Styles to render an editor with a sidebar for comments & suggestions */
.container {
    display: flex;
    flex-direction: row;
}
.document-outline-container {
    background-color: #f3f7fb;
    width: 200px;
}
.sidebar {
    width: 320px;
}
#editor-container .ck.ck-editor {
    width: 860px;
}
#editor-container .sidebar {
    margin-left: 20px;
}
#editor-container .sidebar.narrow {
    width: 30px;
}
/* Keep the automatic height of the editor for adding comments */
.ck-annotation-wrapper .ck.ck-editor__editable_inline {
    min-height: auto;
}
/* Styles for viewing revision history */
#revision-viewer-container {
    display: none;
}
#revision-viewer-container .ck.ck-editor {
    width: 860px;
}
#revision-viewer-container .ck.ck-content {
    min-height: 400px;
}
#revision-viewer-container .sidebar {
    border: 1px #c4c4c4 solid;
    margin-left: -1px;
    width: 320px;
}
#revision-viewer-container .ck.ck-revision-history-sidebar__header {
    height: 39px;
    background: #FAFAFA;
}
.hidden {
    display: none!important;
}



/* Custom styles for Quill editor */
.ql-editor {
    font-size: 14px;
    color: #333;
    direction: rtl !important;
    height: 450px;
    background-color: #f3f3fe;
    width: 100%;
    /*text-align: right;*/
    /* Add your other styles here */
}

.ql-toolbar .ql-header-3,
.ql-toolbar .ql-header-4 {
    width: 20px;
    height: 20px;
}

/* Custom styles for Quill toolbar */
.ql-toolbar {
    background-color: #050c34;
    border: none;
    border-radius: 4px;
    /* Add your other styles here */
}

.ql-formats{
    margin-right: 0px;
}

/* Customize individual toolbar buttons */
.ql-toolbar .ql-bold {
    background-color: #ffcc00;
    color: #333;
    /* Add your other styles here */
}

/* Customize toolbar button hover state */
.ql-toolbar .ql-bold:hover {
    /*background-color: #ff9900;*/
    /* Add your other styles here */
}

/* toolbar-icons.css */
.ql-toolbar .ql-icon {
    color: red; /* Change the color of all icons in the toolbar */
    /* Adjust other styles as needed */
}

/*.ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg{*/
/*    position: absolute;*/
/*    margin-top: -9px;*/
/*    left: 0;*/
/*    top: 50%;*/
/*    width: 18px;*/
/*}*/



/*.ql-picker-label svg{*/
/*    position: absolute;*/
/*    margin-top: -9px;*/
/*     right: 0 !important;*/
/*    top: 50%;*/
/*    width: 18px;*/
/*}*/



