.checkbox-wrapper input[type="checkbox"] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    border-radius: 0.15em;
    margin-right: 0.5em;
    border: 0.15em solid #64b200;
    outline: none;
    cursor: pointer;
    margin: 0;
    margin-top: 5px;
  }


  input.checkbox-wrapper.checked {
    background-color: #64b200;
    position: relative;
  }


  input.checkbox-wrapper.checked::before {
    content: "\2713";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
  }


  .checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }
  
  .checkbox-wrapper input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }
  
  input.checkbox-wrapper:checked {
    background-color: #64b200;
    position: relative;
  }
  
  input.checkbox-wrapper:checked::before {
    content: "\2713";
    font-size: 1.5em;
    color: #fff;
    position: absolute;
    right: 1px;
    top: -5px;
  }